.kt-heading--sm {
  font-size: 1.2rem;
}
.custom-border-brand {
  border-bottom: 2px solid rgba(93, 120, 255, 0.2);
  padding-bottom: 7px;
}
.kt-heading {
  font-size: 1.3rem;
  font-weight: 500;
  padding: 0;
  margin: 1.75rem 0;
  color: #48465b;
}
.kt-widget__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-bottom: 0.5rem;
}

.kt-widget__label {
  color: #48465b;
  font-weight: 500;
}

.tooltip-tfi {
  width: 100%;
}

@media only screen and (max-width: 700px) {
  .tooltip-tfi {
    width: 280px;
  }
}
